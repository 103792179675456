import { Component } from 'react';
import PropTypes from 'prop-types';
import { trackMetric } from '../utils/trackMetric';

export class TrackImpression extends Component {
  componentDidMount() {
    const { metric } = this.props;

    trackMetric(metric);
  }

  render() {
    return this.props.children;
  }
}

TrackImpression.propTypes = {
  metric: PropTypes.array.isRequired,
  children: PropTypes.any.isRequired
};

export default TrackImpression;
