import PropTypes from 'prop-types';
import React from 'react';
import { UX2 } from '@wsb/guac-widget-core';
import { Duration as LuxonDuration } from 'luxon';
import { IntlMessageFormat } from 'intl-messageformat';

export default function Duration({ duration, staticContent }) {
  const { Element } = UX2.Element;

  // Requires ISO8601 duration
  const { days, hours, minutes } = LuxonDuration.fromISO(duration);

  return (
    <Element style={{ whiteSpace: 'nowrap' }}>
      { new IntlMessageFormat(staticContent.duration).format({
        days,
        hours,
        minutes
      }) }
    </Element>
  );
}

Duration.propTypes = {
  duration: PropTypes.string,
  staticContent: PropTypes.object.isRequired
};
