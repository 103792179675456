import FormattersStore from '../stores/Formatters';
import dataAids from '../constants/dataAids';
import { costTypes } from '../constants/costTypes';

const getCostObject = (service, staticContent = {}) => {
  const { numberFormatter } = FormattersStore.getState();
  if (service.cost_type === costTypes.CUSTOM_TEXT) {
    return {
      text: service.custom_cost_text,
      dataAid: dataAids.SERVICE_COST_CUSTOM_TEXT
    };
  }
  if (service.cost_type === costTypes.FREE || +service.cost === 0) {
    return {
      text: staticContent.free,
      dataAid: dataAids.SERVICE_COST_FREE
    };
  }
  const isTaxIncluded = Boolean(service.tax_category && service.tax_category.tax_included_in_price);
  return {
    text: numberFormatter.formatCurrency(
      Number(isTaxIncluded ? service.cost_with_tax : service.cost),
      { withDecimal: false }
    ),
    dataAid: dataAids.SERVICE_COST_COST
  };
};

export const getCostText = (service, staticContent) => {
  return getCostObject(service, staticContent).text;
};

export const getCostDataAid = service => {
  return getCostObject(service).dataAid;
};
